import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";

const AppAnnounce = () => {
  const [list, setList] = useState({});

  const getData = async () => {
    let param = window.location.pathname;
    let noticeId = param.match(/\d+/);
    noticeId = parseInt(noticeId[0]);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_DOMAIN}/app/board/notice/${noticeId}`
      );
      setList(res.data.data[0]);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <Tmp>
      {list?.title ? (
        <Content>
          <Title dangerouslySetInnerHTML={{ __html: list.title }}></Title>
          <hr />
          <Cont dangerouslySetInnerHTML={{ __html: list.contents }}></Cont>
        </Content>
      ) : null}
    </Tmp>
  );
};
const Tmp = styled.div`
  background-color: white;
  width: 100vw;
  min-width: 100%;
  min-height: 100vh;
  height: 100%;
`;
const Content = styled.div`
  padding: 24px 16px;
  word-break: keep-all;
  word-wrap: break-word;

  a {
    color: #262626;
  }

  hr {
    margin: 24px 0px;
    background-color: #e0e0e0;
  }

  img {
    width: 100%;
    max-width: 1200px;
    margin-bottom: 10px;
    object-fit: cover;
  }
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: #262626;
`;

const Cont = styled.div`
  font-size: 16px;
  font-weight: normal;
  color: #262626;
`;
export default AppAnnounce;
