import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";

const MarketingPolicy = () => {
	const [data, setData] = useState([]);
	const getData = async () => {
		try{
			const res = await axios.get(`${process.env.REACT_APP_BASE_DOMAIN}/app/member/auth-terms/public/ADVERTISEMENT`);
			setData(res.data.data.content);
		} catch(err){
			console.log(err)
		}
	}
	useEffect(()=> {
		getData();
	}, [])

	return (
		<Tmp dangerouslySetInnerHTML={{__html : data}}>
		</Tmp>
	)
}
const Tmp = styled.div`
	background-color: white;
	min-height: 100vh;
	height: 100%;
	margin-top: 24px !important;
	margin-bottom: 24px !important;
	margin-right: 16px !important;
	margin-left: 16px !important;
`
export default MarketingPolicy;