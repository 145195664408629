import React, { useEffect, useState } from "react";
// import styled from "styled-components";
import axios from "axios";

const TermsOfLocationInformation = () => {
	const [data, setData] = useState([]);
	const getData = async () => {
		try{
			const res = await axios.get(`${process.env.REACT_APP_BASE_DOMAIN}/app/member/auth-terms/public/LOCATION`);
			setData(res.data.data.content);
		} catch(err){
			console.log(err)
		}
	}
	useEffect(()=> {
		getData();
	}, [])

	const style={
		paddingTop: "24px",
		paddingBottom: "24px",
		paddingRight: "16px",
		paddingLeft: "16px",
		minHeight: "100vh",
		height: "100%",
	}

	return (
		<div dangerouslySetInnerHTML={{__html : data}} style={style}></div>
	)
}
// const Tmp = styled.div`
// 	background-color: white;
// 	min-height: 100vh;
// 	height: 100%;

// 	.ttmp{
// 		margin-top: 24px !important;
// 		margin-bottom: 24px !important;
// 		margin-right: 16px !important;
// 		margin-left: 16px !important;
// 	}
// `

export default TermsOfLocationInformation;