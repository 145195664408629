import GlobalStyle from "./style/global";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// app 약관
import PrivacyPolicy from "./Component/Provision/PrivacyPolicy";
import TermsOfService from "./Component/Provision/TermsOfService";
import MarketingPolicy from "./Component/Provision/MarketingPolicy";
import TermsOfLocationInformation from "./Component/Provision/TermsOfLocationInformation";
// 앱 공지
import AppAnnounce from "./Component/Announce/AppAnnounce";
import AppEvent from "./Component/Announce/AppEvent";

function App() {
  return (
    <BrowserRouter>
      <GlobalStyle />
      <Routes>
        {/* 약관 */}
        <Route path ="/TERMS_OF_SERVICE" element={<TermsOfService />} />
        <Route path ="/PRIVACY_POLICY" element={<PrivacyPolicy />} />
        <Route path ="/MARKETING_POLICY" element={<MarketingPolicy />} />
        <Route path ="/TERMS_OF_LOCATION_INFORMATION" element={<TermsOfLocationInformation />} />
        {/* 앱공지 */}
        <Route path ="/NOTICE/:noticeId" element={<AppAnnounce />}/>
        <Route path ="/EVENT/:eventId" element={<AppEvent />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
